import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { logoutRequest } from 'store/auth/reducer';
import { authSelector } from 'store/auth/selector';

import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { AccountCircle, PowerSettingsNew } from '@mui/icons-material';
import { Divider, MenuItem, Tooltip } from '@mui/material';

import { Wrapper, UserInfoContainer, MobileStyledMenu } from './styles';
import AppBarNotification from './Notification';

export type UserProps = {
  name: string;
  group: string;
};

export default function AppBarUserInfo(props: UserProps) {
  const { name, group } = props;
  const { auth, roles } = useSelector(authSelector);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const isAdmin = React.useMemo(() => {
    return roles?.includes('admin');
  }, [roles]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout(event: React.MouseEvent<HTMLElement>) {
    event.stopPropagation();
    event.preventDefault();
    dispatch(logoutRequest());
    navigate('/login');
  }

  function navigateToUserDetail(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    if (isAdmin) {
      event.stopPropagation();
      return false;
    }

    const tab = roles?.includes('bmf') ? 'bmf' : 'supplier';
    navigate(`/users_and_accesses/${tab}/${auth?.company_id}/users/${auth?.id}`);
  }

  return (
    <UserInfoContainer id="UserInfoContainer" isAdmin={isAdmin}>
      <IconButton
        className="DesktopIconButton"
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="false"
        color="inherit"
        onClick={navigateToUserDetail}>
        <AccountCircle />
      </IconButton>
      <Wrapper className="MobileIconButton">{MobileUserInfoElement()}</Wrapper>
      {UserInfoElement()}
    </UserInfoContainer>
  );

  function MobileUserInfoElement() {
    return (
      <>
        {auth?.uptime_monitor ? <AppBarNotification /> : null}
        <Wrapper sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
          <Tooltip title="Account settings">
            <IconButton
              className="MobileIconButton account"
              size="large"
              sx={{ mr: 1.4 }}
              aria-label="account of current user"
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              color="inherit"
              onClick={handleClick}>
              <AccountCircle />
            </IconButton>
          </Tooltip>
        </Wrapper>
        <MobileStyledMenu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 0.3,
                marginRight: 2,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 0.5
                },
                '&::before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0
                }
              }
            }
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
          <MenuItem onClick={navigateToUserDetail}>
            <Typography className="name" m={1}>
              {name}
            </Typography>
            <Typography className="separator">{'/'}</Typography>
            <Typography className="group" m={1}>
              {group}
            </Typography>
          </MenuItem>
          <Divider />
          <MenuItem>
            <IconButton
              aria-label="logout user"
              color="inherit"
              onClick={handleLogout}
              sx={{ padding: '8px' }}>
              <PowerSettingsNew />
              <Typography component={'span'} sx={{ ml: 2 }}>
                Logout
              </Typography>
            </IconButton>
          </MenuItem>
        </MobileStyledMenu>
      </>
    );
  }

  function UserInfoElement() {
    return (
      <Wrapper className="desktop" onClick={navigateToUserDetail} link={true}>
        <Wrapper>
          <Typography className="name" m={1}>
            {name}
          </Typography>
          <Typography className="separator">{'/'}</Typography>
          <Typography className="group" m={1}>
            {group}
          </Typography>
        </Wrapper>
        {auth?.uptime_monitor ? <AppBarNotification /> : null}
        <IconButton
          size="large"
          aria-label="logout user"
          aria-controls="menu-appbar"
          aria-haspopup="false"
          color="inherit"
          className="account"
          onClick={handleLogout}>
          <PowerSettingsNew />
        </IconButton>
      </Wrapper>
    );
  }
}
