import { styled, alpha } from '@mui/material/styles';

import AppBar from '@mui/material/AppBar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Menu from '@mui/material/Menu';

import breakpoints from 'types/breakpoints';

export const StyledAppBar = styled(AppBar)`
  display: flex;

  .DesktopAppBarContainer {
    display: none;
  }

  .MobileAppBarContainer {
    display: flex;
  }

  ${breakpoints.xl} {
    .DesktopAppBarContainer {
      display: flex;
    }

    .MobileAppBarContainer {
      display: none;
    }
  }
`;

export const StyledSearchInput = styled('div')`
  margin-right: 12px !important;
  width: auto;
`;

export const StyledIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.common.white};
  margin-left: 16px;

  .MuiCollapse-horizontal {
    display: flex;
  }
`;

export const StyledSearch = styled('div')`
  display: flex;
  flex-direction: row-reverse;
  position: relative;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  background-color: ${({ theme }) => alpha(theme.palette.common.white, 0.85)};
  color: ${({ theme }) => theme.colors.gray.dark};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-left: 0;
  margin-right: 0;
  width: 60%;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.palette.common.white, 1)};

    svg {
      color: ${({ theme }) => theme.colors.gray.average};
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-left: ${({ theme }) => theme.spacing(1)};
    width: auto;
  }

  input {
    cursor: pointer !important;
  }

  ${breakpoints.md} {
    margin-right: 24px;
  }
`;

export const SearchIconWrapper = styled('div')`
  padding: ${({ theme }) => theme.spacing(1, 1)};
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;

  svg {
    color: ${({ theme }) => theme.colors.gray.medium};
  }
`;

export const StyledInputBase = styled(InputBase)<{ width?: string }>`
  color: inherit;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;

  .MuiInputBase-input {
    padding: ${({ theme }) => theme.spacing(1, 0, 1, 2)};
    padding-right: ${({ theme }) => `calc(1em + ${theme.spacing(4)})`};
    transition: ${({ theme }) => theme.transitions.create('width')};
    width: ${({ width }) => width ?? '160px'};
  }
`;

export const UserInfoContainer = styled(Box)<{ isAdmin?: boolean }>`
  display: flex;
  margin-right: 0;
  justify-content: center;

  > .MuiBox-root,
  > button {
    cursor: ${({ isAdmin }) => (isAdmin ? 'default' : 'pointer')} !important;
  }

  .name,
  .group,
  .separator {
    font-size: 16px;
  }

  .desktop,
  .DesktopIconButton {
    display: none;
  }

  .MobileIconButton {
    display: flex;
  }

  .group {
    margin-right: 24px;
  }

  .account {
    padding: 0px;
    padding-left: 12px;
    padding-right: 4px;

    ${({ theme }) => theme.breakpoints.up('md')} {
      padding: 12px;
    }
  }

  ${breakpoints.xl} {
    .desktop,
    .DesktopIconButton {
      display: flex;
    }

    .MobileIconButton {
      display: none;
    }
    margin-right: 16px;
  }
`;

export const Wrapper = styled(Box)<{ link?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  &.mobile {
    flex-direction: column;
  }

  &.PageNav {
    ${breakpoints.xl} {
      gap: 4px;
    }

    span {
      font-size: 13px;
      ${breakpoints.xl} {
        font-size: 16px;
      }
    }
  }

  ${({ link }) =>
    link
      ? `
  &.desktop:hover {
    cursor: pointer;
  }`
      : ''}
  .name,
  .separator,
  .group {
    text-wrap: nowrap;
    font-size: clamp (12px, 4vw, 16px);
  }
`;

export const StyledMenu = styled(Menu)`
  background-color: transparent;
  left: 0px;
  z-index: 0;

  .MuiMenu-paper {
    box-shadow: none;
    background-color: transparent;
  }

  &.mobile {
    .MuiPaper-root {
      background-color: ${({ theme }) => theme.colors.primary.main} !important;
      left: 0 !important;
      width: 64px;
      height: 100vh;
      top: 48px !important;
      max-height: calc(100% - 44px);
      svg {
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }

  ul.MuiMenu-list {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 0;
    left: 0px;

    ul {
      margin-top: 16px;
      background-color: transparent;
    }

    li {
      display: flex;
      background-color: transparent;
      height: 46px;
      width: 100%;
      padding: 4px;

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray.medium};
      }

      > div {
        display: flex;
        width: 100%;
        justify-content: center;
      }
    }
  }
`;

export const MobileStyledMenu = styled(Menu)`
  ul > li.Mui-disabled {
    opacity: 100%;
  }
`;

export const BadgeContainer = styled(Badge)`
  .MuiBadge-badge {
    top: 4px;
    font-size: 4px;
    border-radius: 10px;
    min-width: 10px;
    height: 10px;
    color: ${({ theme }) => theme.colors.error.main};
    padding: 0px;
  }
`;
