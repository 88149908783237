import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';

import { BadgeContainer } from './styles';
import { DropdownMenuItem } from 'store/app/interface';

export default function IconMenuItem(props: DropdownMenuItem) {
  const { title, path, count = 0, ...restProps } = props;
  const navigate = useNavigate();

  const menuItem = React.useMemo(() => {
    if (restProps.withCount && Number(count) > 0) {
      return (
        <BadgeContainer badgeContent="o" color="error">
          {restProps.icon}
        </BadgeContainer>
      );
    }

    return restProps.icon;
  }, [restProps]);

  return (
    <Tooltip title={title} arrow placement="right">
      <MenuItem onClick={() => path && navigate(path)}>
        <ListItemIcon>{menuItem}</ListItemIcon>
      </MenuItem>
    </Tooltip>
  );
}
