import {
  Euro,
  GppMaybe,
  Groups,
  IntegrationInstructions,
  ListAlt,
  LocalShipping,
  Repartition,
  Settings,
  ShapeLine,
  Wallet,
  Warehouse
} from '@mui/icons-material';

export const landing = [
  {
    icon: <ListAlt />,
    title: 'Orders listing',
    key: 'orders',
    withCount: false,
    path: '/orders',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Warehouse />,
    title: 'Products',
    key: 'products',
    withCount: true,
    path: '/products',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Warehouse />,
    title: 'Production demand',
    key: 'production_demand',
    withCount: true,
    path: '/production_demand',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Euro />,
    title: 'Price agreements',
    key: 'price_agreements',
    withCount: true,
    path: '/price_agreements',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <LocalShipping />,
    title: 'Deliveries',
    key: 'deliveries',
    withCount: false,
    path: '/deliveries',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Repartition />,
    title: 'Spare parts',
    key: 'spare_parts',
    withCount: false,
    path: '/spare_parts',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <ShapeLine />,
    title: 'Documents',
    key: 'documents',
    withCount: false,
    path: '/documents',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <IntegrationInstructions />,
    title: 'Merit integration',
    key: 'merit_integration',
    withCount: false,
    path: '/merit_integration',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Wallet />,
    title: 'Invoices',
    key: 'invoices',
    withCount: false,
    path: '/invoices',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Groups />,
    title: 'Users & accesses',
    key: 'users_and_accesses',
    withCount: false,
    path: '/users_and_accesses/supplier',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <GppMaybe />,
    title: 'Warranty Cases',
    key: 'warranty_cases',
    withCount: false,
    path: '/warranty_cases',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Settings />,
    title: 'Settings',
    key: 'settings',
    withCount: false,
    path: '/settings',
    permitted_roles: ['admin', 'bmf']
  }
];

export const dropdown = [
  {
    icon: <Warehouse />,
    title: 'Production demand',
    key: 'production_demand',
    withCount: true,
    path: '/production_demand',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Euro />,
    title: 'Price agreements',
    key: 'price_agreements',
    withCount: true,
    path: '/price_agreements',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <ListAlt />,
    title: 'Orders listing',
    key: 'orders',
    withCount: false,
    path: '/orders',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <LocalShipping />,
    title: 'Deliveries',
    key: 'deliveries',
    withCount: false,
    path: '/deliveries',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Repartition />,
    title: 'Spare parts',
    key: 'spare_parts',
    withCount: false,
    path: '/spare_parts',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <ShapeLine />,
    title: 'Documents',
    key: 'documents',
    withCount: false,
    path: '/documents',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <IntegrationInstructions />,
    title: 'Merit integration',
    key: 'merit_integration',
    withCount: false,
    path: '/merit_integration',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Wallet />,
    title: 'Invoices',
    key: 'invoices',
    withCount: false,
    path: '/invoices',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Groups />,
    title: 'Users & accesses',
    key: 'users_and_accesses',
    withCount: false,
    path: '/users_and_accesses/supplier',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <GppMaybe />,
    title: 'Warranty Cases',
    key: 'warranty_cases',
    withCount: false,
    path: '/warranty_cases',
    permitted_roles: ['admin', 'bmf', 'supplier']
  },
  {
    icon: <Settings />,
    title: 'Settings',
    key: 'settings',
    withCount: false,
    path: '/settings',
    permitted_roles: ['admin', 'bmf']
  }
];

export const pages = [
  {
    title: 'Orders',
    key: 'orders',
    path: '/orders'
  },
  {
    title: 'Products',
    key: 'products',
    path: '/products'
  },
  {
    title: 'Demand',
    key: 'production_demand',
    path: '/production_demand'
  },
  {
    title: 'Agreements',
    key: 'price_agreements',
    path: '/price_agreements'
  },
  {
    title: 'Deliveries',
    key: 'deliveries',
    path: '/deliveries'
  },
  {
    title: 'Spares',
    key: 'spare_parts',
    path: '/spare_parts'
  },
  {
    title: 'Warranty',
    key: 'warranty_cases',
    path: '/warranty_cases'
  }
];

export const DELIVERY = 0;
export const CORRECTION = 1;
export const FINISHED_STATUS = 3;

export const entryTypes = [
  { label: 'Delivery', value: DELIVERY },
  { label: 'Correction', value: CORRECTION }
];

export const CHANNEL_NOTE = -1;
export const CHANNEL_EMAIL = 0;
export const CHANNEL_PHONE = 1;
export const CHANNEL_SMS = 2;
export const CHANNEL_CHAT = 3;

export const noteChannels = [
  { value: CHANNEL_NOTE, label: 'Note' },
  { value: CHANNEL_EMAIL, label: 'Email' },
  { value: CHANNEL_PHONE, label: 'Phone' },
  { value: CHANNEL_SMS, label: 'SMS' },
  { value: CHANNEL_CHAT, label: 'Chat' }
];
