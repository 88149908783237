import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';

import { Wrapper } from './styles';
import IconMenu from './IconMenu';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { clearSearch, setTableFilter } from 'store/data/reducer';

type MenuProps = {
  pages: any;
  menu: any;
};

export default function AppBarDesktopMenu(props: MenuProps) {
  const { pages, menu } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <IconMenu id="icon-dropdown-desktop-menu" styles={{ mr: 1 }} menuItems={menu} />
      <Typography
        variant="h6"
        noWrap
        component="a"
        href="#"
        sx={{
          mr: 2,
          fontWeight: 500,
          color: 'inherit',
          textDecoration: 'none',
          fontSize: '1.75rem'
        }}
        onClick={(event) => {
          event.stopPropagation();
          event.preventDefault();
          dispatch(clearSearch());
          navigate('/portal');
        }}>
        {process.env.REACT_APP_NAME}
      </Typography>
      <Wrapper className="PageNav" sx={{ justifyContent: 'space-between' }}>
        {pages.map((page: any) => (
          <Button
            key={page.key}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              dispatch(setTableFilter(InitialTableFilterValue));
              dispatch(clearSearch());
              navigate(page.path);
            }}
            sx={{ color: 'white', width: 'auto' }}>
            <Typography component={'span'}>{page.title}</Typography>
          </Button>
        ))}
      </Wrapper>
    </React.Fragment>
  );
}

export const InitialTableFilterValue = {
  products: {
    stock_below_the_minimum: null,
    product_with_orders: null,
    search: null
  },
  production_demand: {
    stock_below_the_minimum: null,
    exclude_zero_plans: null,
    exclude_zero_orders: null,
    search: null
  },
  price_agreements: {
    status_valid: null,
    status_invalid: null,
    status_expired: null,
    status_discarded: null,
    price_missing: null,
    search: null
  },
  deliveries: {
    bound_with_invoices: null,
    search: null,
    date: {
      start: null,
      end: null
    }
  },
  spare_parts: {
    with_spares: null,
    search: null
  },
  documents: {
    search: null
  },
  warranty_cases: {
    new_cases: null,
    open: null,
    open_by_bmf: null,
    updates: null,
    case_status: null,
    bmf_status: null,
    search: null
  },
  merit_integration: {
    show_only_unbound: null,
    show_only_ignored: null,
    search: null
  },
  users: {
    search: null
  }
};
