import axiosLib from 'axios';

import CryptoJS from 'crypto-js';

export function genericTexts(field: string) {
  return {
    isRequired: '{field} is required'.replace(/{field}/g, field),
    isNumber: '{field} must be a number'.replace(/{field}/g, field),
    isValid: '{field} must be valid'.replace(/{field}/g, field),
    isValidEmail: '{field} must be a valid email'.replace(/{field}/g, field)
  };
}

export function newAbortSignal() {
  const controller = new AbortController();

  const CancelToken = axiosLib.CancelToken;
  const source = CancelToken.source();

  return { controller, source };
}
export function getValidationResult<T>(data: T, schema: any) {
  try {
    schema.validateSync(data, { abortEarly: false, allowUnknown: true });
    return {
      errors: {},
      isValid: true
    };
  } catch (err: any) {
    const errors = err.inner.reduce((acc: any, el: any) => {
      const { path, message } = el;
      acc[path] = message;
      return acc;
    }, {});
    return { isValid: false, errors };
  }
}

export function getResponseMessage(response: any, messageType = 'error') {
  let message = messageType === 'success' ? 'Success' : 'Something went wrong';

  if (typeof response?.data?.message === 'string') {
    message = response?.data?.message;
  }

  if (typeof response?.message === 'string') {
    message = response?.message;
  }

  if (typeof response?.data?.message === 'object') {
    message = Object.entries(response?.data?.message).reduce((text, [key, value]: any) => {
      return (text += `${value}\n`);
    }, '');
  }

  return message;
}

export const filterMenu = (modules: any, items: any, roles?: any) => {
  if (roles) {
    return items.reduce((acc: any, item: any) => {
      const module = modules?.find((module: any) => module.name === item.key);
      if (item?.permitted_roles) {
        const permitted = item.permitted_roles.some((role: any) => roles.includes(role));
        if (permitted && module) {
          return acc.concat({
            ...item,
            withCount: module.count > 0,
            count: module.count
          });
        }
      }

      if (!module) {
        return acc;
      }

      return acc.concat({
        ...item,
        withCount: module.count > 0,
        count: module.count
      });
    }, []);
  }

  return items.reduce((acc: any, item: any) => {
    const module = modules?.find((module: any) => module.name === item.key);
    if (!module) {
      return acc;
    }

    return acc.concat({
      ...item,
      withCount: module.count > 0,
      count: module.count
    });
  }, []);
};

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function snakeCaseToTitleCase(value: string) {
  return value.replace(/^_*(.)|_+(.)/g, (value, search, replace) => {
    return search ? search.toUpperCase() : ' ' + replace.toUpperCase();
  });
}

export function snakeCaseToNormalCase(value: string) {
  return value.replace(/^_*(.)|_+(.)/g, (value, search, replace) => {
    return search ? search : ' ' + replace;
  });
}

export function a11yProps(name: string, aria: string, index: number) {
  return {
    id: `${name}-${index}`,
    'aria-controls': `${aria}-${index}`
  };
}

export const GOOGLE_AUTHENTICATOR = {
  ios: 'https://apps.apple.com/au/app/google-authenticator/id388497605',
  google: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
};

export const parseFileData = async (data: any, index: string, file: any) => {
  const formData = new FormData();

  Object.entries(data).forEach(([key, value]) => {
    const formValue = value as any;
    formData.append(key, formValue);
  });

  const fileData = await fetch(file)
    .then((res) => res.blob())
    .then((blob) => {
      return new File([blob], file.name, { type: file.type });
    });

  formData.append(index, fileData, file.type);

  return formData;
};

export const formatToValidDate = (date: string) => {
  const split = date.split('.');
  return split[2] + '-' + split[1] + '-' + split[0];
};

export const decryptSecurityKey = (data: string) => {
  const decoded = CryptoJS.enc.Base64.parse(data).toString(CryptoJS.enc.Latin1);
  const encryptionKey = process.env.REACT_APP_FIRST_ENCRYPTION_KEY || '';

  if (!encryptionKey || encryptionKey === '') {
    return '';
  }

  const key = CryptoJS.enc.Base64.parse(encryptionKey).toString(CryptoJS.enc.Latin1);

  const length = 16;
  const iv = CryptoJS.enc.Latin1.parse(decoded.substring(0, length));
  const firstEncrypted = CryptoJS.enc.Latin1.parse(decoded.substring(length + 64));

  const ciphertext = CryptoJS.lib.CipherParams.create({ ciphertext: firstEncrypted });
  const config = { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };

  const decrypted = CryptoJS.AES.decrypt(ciphertext, CryptoJS.enc.Latin1.parse(key), config);
  const decryptedText = decrypted.toString(CryptoJS.enc.Latin1);

  return CryptoJS.enc.Base64.parse(decryptedText).toString(CryptoJS.enc.Utf8);
};

export const getFileExtension = (fileName: string) => {
  const parts = fileName.split('.');
  return parts.length > 1 ? parts.pop()?.toLowerCase() : '';
};
